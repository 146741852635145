import React from 'react';
import './MysteryButton.css';

const MysteryButton = ({ onClick }) => {
  return (
    <div className="mystery-container">
    <button className="mystery-button" onClick={onClick}>
      Click if you dare...
    </button>
    </div>
  );
};

export default MysteryButton;
