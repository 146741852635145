import React from 'react';
import './Puzzle.css';

const Puzzle = ({ onSolve, solved }) => {
  return (
    <div className="p-container">
    <div className={`puzzle-container ${solved ? '' : 'retro-tv'}`}>
      <div className="puzzle-grid">
        {[15, 16, 17, 18].map((item) => (
          <div 
            key={item} 
            className="puzzle-piece"
            onClick={() => !solved && item === 18 && onSolve()}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="puzzle-hint">Find the correct piece to reveal your surprise!</div>
    </div>
    </div>
  );
};

export default Puzzle;
