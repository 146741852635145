import React from 'react';
import './Countdown.css';

const Countdown = ({ timeLeft }) => {
  return (
    <div className="countdown-container">
      <div className="timer">
        <span>{timeLeft.days}</span>
        <span className="time-label">days</span>
        <span>{String(timeLeft.hours).padStart(2, '0')}</span>
        <span className="time-label">hours</span>
      </div>
      <div className="scary-text">Until the deadline...</div>
    </div>
  );
};

export default Countdown;
