import { useState, useEffect, useRef } from 'react';
import './App.css';
import Countdown from './components/Countdown/Countdown';
import MysteryButton from './components/MysteryButton/MysteryButton';
import Puzzle from './components/Puzzle/Puzzle';
import Message from './components/Message/Message';
import AnimatedCake from './components/AnimatedCake/AnimatedCake';
import Clouds from './components/Clouds/Clouds';
import Stars from './components/Stars/Stars';
import ReplayButton from './components/ReplayButton/ReplayButton';
import wrongPlaceAudio from './assets/wrong-place.mp3';
import avoidHorrorAudio from './assets/avoid-horror.mp3';
import chirazAudio from './assets/Chiraz.mp3';

function App() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [gameState, setGameState] = useState('countdown');
  const [puzzleSolved, setPuzzleSolved] = useState(false);
  const [message, setMessage] = useState('');
  const [highlightIndex, setHighlightIndex] = useState(0);
  
  const wrongPlaceRef = useRef(new Audio(wrongPlaceAudio));
  const avoidHorrorRef = useRef(new Audio(avoidHorrorAudio));
  const chirazRef = useRef(new Audio(chirazAudio));

  // Preload audio files
  useEffect(() => {
    wrongPlaceRef.current.preload = 'auto';
    avoidHorrorRef.current.preload = 'auto';
    chirazRef.current.preload = 'auto';
  }, []);

  const replayChirazSong = () => {
    chirazRef.current.currentTime = 0;
    chirazRef.current.play().catch(error => console.log('Audio play failed:', error));
  };

  function calculateTimeLeft() {
    const targetDate = new Date('2025-05-27T00:00:00');
    const now = new Date();
    const difference = targetDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return { days, hours, total: difference };
  }

  useEffect(() => {
    const audio = wrongPlaceRef.current;
    audio.loop = true;
    audio.volume = 1.0;
    
    const playAudio = async () => {
      try {
        if (gameState === 'countdown' && timeLeft.total > 0) {
          await audio.play();
        } else {
          audio.pause();
          audio.currentTime = 0;
        }
      } catch (error) {
        console.log('Audio play failed:', error);
      }
    };

    playAudio();

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      
      if (newTimeLeft.total <= 0 && gameState === 'countdown') {
        audio.pause();
        audio.currentTime = 0;
        clearInterval(timer);
        setGameState('button');
      }
    }, 32000);

    return () => {
      clearInterval(timer);
      audio.pause();
      audio.currentTime = 0;
    };
  }, [gameState, timeLeft.total]);

  const startPuzzle = () => {
    setGameState('puzzle');
    avoidHorrorRef.current.play().catch(error => console.log('Audio play failed:', error));
  };

  const solvePuzzle = () => {
    setPuzzleSolved(true);
    setGameState('surprise');
    avoidHorrorRef.current.play().catch(error => console.log('Audio play failed:', error));
    setTimeout(() => {
      chirazRef.current.play().catch(error => console.log('Audio play failed:', error));
    }, 500);
    
    const celebrationMessage = "Joyeux anniversaire ma soeur Chiraz la plus belle personne dans mon vie, et merci pour touts Loobyouu, etttt Rbi Ykhlikk liyaaa w ywaf9ek incha'allah!";
    setMessage(celebrationMessage);
    let index = 0;
    const highlightInterval = setInterval(() => {
      if (index < celebrationMessage.split(' ').length) {
        setHighlightIndex(index);
        index++;
      } else {
        clearInterval(highlightInterval);
      }
    }, 500);
  };

  return (
    <div className="App">
      {gameState === 'countdown' && timeLeft.total > 0 && (
        <Countdown timeLeft={timeLeft} />
      )}

      {gameState === 'button' && (
        <MysteryButton onClick={startPuzzle} />
      )}

      {gameState === 'puzzle' && (
        <Puzzle onSolve={solvePuzzle} solved={puzzleSolved} />
      )}

      {gameState === 'surprise' && (
        <div className="surprise-container">
          <ReplayButton onClick={replayChirazSong} />
          <div className="sky-container">
            <Stars />
            <Clouds />
            <AnimatedCake />
          </div>
          <Message message={message} highlightIndex={highlightIndex} />
        </div>
      )}
    </div>
  );
}

export default App;
